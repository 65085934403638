import { useState, useEffect, useRef, PropsWithChildren } from 'react'

import useViewport from 'libs/use-viewport'

import { debounce } from 'utils/debounce'

import cn from 'classnames'

import IconInfo from 'images/icon-info.svg'
import IconInfoBlue from 'images/icon-info-blue.svg'

import styles from './styles.module.scss'

function getCoords(elem: HTMLElement) {
  const box = elem.getBoundingClientRect()

  const body = document.body
  const docEl = document.documentElement

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

  const clientTop = docEl.clientTop || body.clientTop || 0
  const clientLeft = docEl.clientLeft || body.clientLeft || 0

  const top = box.top + scrollTop - clientTop
  const left = box.left + scrollLeft - clientLeft

  return { top: Math.round(top), left: Math.round(left) }
}

interface NoticeProps {
  blue?: boolean
  big?: boolean
  alt: string
  questionPosition?: string
  boxPosition?: string
  leftPos?: string
  boxStyle?: string
  imgClassName?: string
  wrapperClassName?: string
}

export default function Notice({
  children,
  alt,
  questionPosition,
  boxPosition,
  blue,
  big,
  leftPos,
  imgClassName,
  wrapperClassName,
}: PropsWithChildren<NoticeProps>) {
  const elementRefs = useRef<{
    box?: HTMLDivElement
    img?: HTMLImageElement
  }>({})
  const [viewport] = useViewport()
  const [left, setLeft] = useState(0)
  const [top, setTop] = useState(0)

  const updateSizes = () => {
    if (!elementRefs.current.box || !elementRefs.current.img) {
      return
    }

    const boxWidth = elementRefs.current.box.clientWidth
    const imgWidth = elementRefs.current.img.clientWidth
    const imgHeight = elementRefs.current.img.clientHeight
    const imgCoors = getCoords(elementRefs.current.img)

    const newLeft = -(imgCoors.left - Math.min(imgCoors.left + imgWidth + 10, window.innerWidth - 10 - boxWidth))
    const newTop = imgHeight + 10

    if (newLeft !== left) setLeft(newLeft)
    if (newTop !== top) setTop(newTop)
  }

  const handleScroll = debounce(updateSizes, 16.6)

  useEffect(() => {
    updateSizes()
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    updateSizes()
  }, [viewport])

  return (
    <div className={cn(styles.noticeWrapper, wrapperClassName)}>
      <div className={cn(styles.notice, questionPosition)}>
        <img
          className={cn(styles.notice_img, { [styles.notice_img_big]: big }, imgClassName)}
          src={blue ? IconInfoBlue : IconInfo}
          alt={alt}
          ref={(el) => {
            elementRefs.current.img = el
          }}
          loading="lazy"
          onClick={(evt) => evt.stopPropagation()}
        />
        <div
          ref={(el) => {
            elementRefs.current.box = el
          }}
          className={cn(styles.box, boxPosition)}
          style={
            viewport.includes('mobile')
              ? {
                  left: leftPos ?? `-165px`,
                  top: `${top}px`,
                }
              : null
          }
        >
          <p className={styles.noticeText}>{children}</p>
        </div>
      </div>
    </div>
  )
}
