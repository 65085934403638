import React from 'react'

const isKeyboardEvent = (event: any): event is React.KeyboardEvent<HTMLInputElement> => {
  return (event as React.KeyboardEvent<HTMLInputElement>).key !== undefined
}

export const adjustCursorPosition = (
  event: React.KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLInputElement>,
) => {
  const { target } = event

  if (!(target instanceof HTMLInputElement)) {
    return
  }

  let cursorPosition = target.selectionStart || 0
  let key: string | null = null

  if (isKeyboardEvent(event)) {
    key = event.key
  }

  if (key === 'ArrowRight') {
    while (cursorPosition < (target.value.length || 0) && target.value[cursorPosition] === ' ') {
      cursorPosition += 1
    }
    target.setSelectionRange(cursorPosition, cursorPosition)
  } else if (key === 'ArrowLeft') {
    while (cursorPosition > 0 && target.value[cursorPosition - 2] === ' ') {
      cursorPosition -= 1
    }
    target.setSelectionRange(cursorPosition, cursorPosition)
  } else if (key === 'Delete') {
    if (cursorPosition < (target.value.length || 0) && target.value[cursorPosition] === ' ') {
      target.setSelectionRange(cursorPosition + 1, cursorPosition + 1)
    }
  } else if (key === null) {
    if (cursorPosition > 0 && target.value[cursorPosition - 1] === ' ') {
      target.setSelectionRange(cursorPosition - 1, cursorPosition - 1)
    }
  }
}
