export function debounce<F extends (...args: any[]) => any>(
  f: F,
  ms: number,
): (...args: Parameters<F>) => ReturnType<F> | null {
  let isCooldown = false

  return function (...args: Parameters<F>): ReturnType<F> | null {
    if (isCooldown) return null
    isCooldown = true
    setTimeout(() => {
      isCooldown = false
    }, ms)
    return f(...args)
  }
}
